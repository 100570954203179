// import '@fortawesome/fontawesome-free/js/fontawesome';
// import '@fortawesome/fontawesome-free/js/solid';
// import '@fortawesome/fontawesome-free/js/brands';

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import SplitType from 'split-type';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');
        $('.mobile-nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    })

    if(windowWidth < 1024) {
	    $('#menu-main-menu .has-mega-menu').on("click", function() {
	       $(this).toggleClass('open');
	       $(this).find('.mega-menu').toggleClass('active');
	    })

	    $('.mega-menu a').on("click", function(event) {
	        event.stopPropagation();
	    });
	}
/* ====================== Semi Sticky Nav ======================  */
	var prevScrollpos = window.pageYOffset;
	var currentScrollPos = window.pageYOffset;

	if(currentScrollPos > 120) {
		$("header").addClass("headerscrolled");
	}

	document.addEventListener('scroll', function (event) {
		var currentScrollPos = window.pageYOffset;

		if ((prevScrollpos > currentScrollPos)) {
			$('header').addClass('show');
		} else if((prevScrollpos < currentScrollPos)){
			$('header').removeClass('show');
		}
		else {
			$('header').removeClass('show');
		}

		if(currentScrollPos > 120) {
			$("header").addClass("headerscrolled");
		} else {
			$("header").removeClass("headerscrolled show");
		}

		prevScrollpos = currentScrollPos;
	}, true);

/* ====================== Accordion ======================  */
	$('.accordion .row-title').on('click', function () {
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).next('.row-content').slideUp();
			$(this).next('.row-content').removeClass('active');
		} else {
			$('.accordion .row-title').removeClass('active');
			$('.row-content').slideUp();
			$('.row-content').removeClass('active');

			$(this).addClass('active');
			$(this).next('.row-content').slideDown();
			$(this).next('.row-content').addClass('active');
		}
    });

/* ====================== Latest Posts Carousel ======================  */
	var numberOfSlides = 3.2;

	if(windowWidth < 768) {
		numberOfSlides = 1.2
	}

	const latest_posts_carousel = new Swiper('.news-carousel.swiper', {
       	slidesPerView: numberOfSlides,
       	speed: 600,
       	loop: false,
       	watchSlidesProgress: true,
       	spaceBetween: 25,
       	navigation: {
			nextEl: ".latest-news-carousel .swiper-button-next",
			prevEl: ".latest-news-carousel .swiper-button-prev",
		},
	});

/* ====================== Related Posts Carousel ======================  */
	var numberOfSlides = 1.2;

	if(windowWidth < 768) {
		const latest_posts_carousel = new Swiper('.interested-in .posts.swiper', {
	       	slidesPerView: numberOfSlides,
	       	speed: 600,
	       	loop: false,
	       	watchSlidesProgress: true,
	       	spaceBetween: 25,
		});
	}

/* ====================== Team Carousel ======================  */
	if(windowWidth < 1024) {
		var numberOfSlides = 2.4;

		if(windowWidth < 768) {
			numberOfSlides = 1.2
		}

		const team_members = new Swiper('.team-members.swiper', {
	       	slidesPerView: numberOfSlides,
	       	speed: 600,
	       	loop: false,
	       	watchSlidesProgress: true,
	       	spaceBetween: 25,
	       	navigation: {
				nextEl: ".latest-news-carousel .swiper-button-next",
				prevEl: ".latest-news-carousel .swiper-button-prev",
			},
		});
	}

/* ====================== Testimonial Carousel ======================  */
	const testimonials_carousel = new Swiper('.testimonials-carousel-element', {
       	slidesPerView: 1.2,
       	speed: 600,
       	loop: false,
       	watchSlidesProgress: true,
       	spaceBetween: 24,
       	navigation: {
			nextEl: ".testimonial-carousel .swiper-button-next",
			prevEl: ".testimonial-carousel .swiper-button-prev",
		},
				breakpoints: {
			768: {
				slidesPerView: 2.2,
			},
			1024: {
				slidesPerView: 3.2,
			},
		},
	});

	/* ====================== Splitlines ======================  */
	const cta = new SplitType('.btn span, .filter span, header nav li:not(.static-btn) span', { types: 'chars' })
	cta.chars

	const headingMask = new SplitType('h1:not(.no-anim),h2:not(.no-anim),h3:not(.no-anim),h4:not(.no-anim)',
	{ types: 'lines,words' })
	headingMask.lines

/* ====================== GSAP ANIMATIONS ======================  */
	ScrollTrigger.matchMedia({
		'(min-width:1024px)':function(){
			let parallax = gsap.utils.toArray('.accent-image:not(.no-anim)');
			var val = -100;

			parallax.forEach((item, index) => {
				if(index == 1) {
					val = -50;
				}
				var trigger = $(item).parent();
				let tl = gsap.timeline({
				scrollTrigger: {
					scrub: 1,
					trigger: trigger[0],
					start:"-500 70%",
					toggleActions:"restart none none reset",
					end: "1000 70%",
					markers: false
				}
				});
				tl.to(item, {
					y: val,
					ease: 'none'
				});
			});
		}
	});

	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 90%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});

	var trigger = '70%';
	if(windowWidth < 1024) {
		trigger = '90%';
	}

	let imageAnimate = gsap.utils.toArray('.image-container')
	imageAnimate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 " + trigger,
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});

	let headingMaskAnim = gsap.utils.toArray('h1:not(.no-anim):not(.homepage-title), h2:not(.no-anim), h3:not(.no-anim)');
	headingMaskAnim.forEach((item, index) => {
		item.style.opacity = 1;

		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start: 'top 95%',
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
		tl.to(item.querySelectorAll(".word"), {
			y: 0,
			duration:3,
			ease: "power4.out",
			opacity: 1,
			duration: .4,
		});
	});

});
